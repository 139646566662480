import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./style.css";


const SafeZone = ({ children, width, ...rest }) => (
  <section {...rest} className={classnames("fpl__safe-zone", `fpl__safe-zone--${width}`)}>
    {children}
  </section>
);

SafeZone.propTypes = {
  width: PropTypes.oneOf(["full", "relative"]),
  children: PropTypes.node.isRequired,
};

SafeZone.defaultProps = {
  width: "full",
};

export default SafeZone;
