/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../header";
import Footer from "../footer";
import ParallaxImage from "../parallaxImage";
import Logo from "../logo";
import "./style.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header>{data.site.siteMetadata.title}</Header>
      <div className="fpl__header-safeguard" />
      <main>{children}</main>
      <Footer />
      <div className="fpl__footer-safeguard" />
      <ParallaxImage
        url="https://images.unsplash.com/photo-1502134249126-9f3755a50d78?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
        height="fullScreen"
      >
        <a href="/">
          <Logo style={{ width: "70vw" }} mode="dark" highlight="white" />
        </a>
      </ParallaxImage>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
