import React from "react";
import { Link } from "gatsby";
import Button from "../button";
import "./style.css";

const footerDate = new Date().getUTCFullYear();

const Footer = (props) => (
  <footer {...props} className="fpl__footer">
    <div className="fpl__footer-column">
      <h4>Get in touch with our team</h4>
      <hr />
      <form
        className="Newsletter-form"
        action="https://formspree.io/sales@farpointlabs.com"
        method="POST"
      >
        <label htmlFor="name">
          Your name
          <input type="text" name="name" id="name" autoComplete="on" />
        </label>
        <label htmlFor="email">
          Your work email
          <input type="email" name="email" id="email" autoComplete="on" />
        </label>
        <input
          type="text"
          placeholder="Don't fill this if you are human"
          className="fpl__input-robit"
          name="robit"
        />
        <Button>Say hello</Button>
      </form>
      <h4>Get in touch directly</h4>
      <hr />
      <p>
        Drop us an email at:
        <br />
        <a href="mailTo:contact@farpointlabs.com">contact@farpointlabs.com</a>
      </p>
      <hr />
      <p>
        Talk to someone at:
        <br />
        <a href="tel:+442034881337">+44 203 488 1337</a>
      </p>
      <hr />
      <p>
        Contact support at:
        <br />
        <a href="mailTo:support@farpointlabs.com">support@farpointlabs.com</a>
      </p>
      <hr />
      <p>
        Find out about our openings at:
        <br />
        <a href="mailTo:join-us@farpointlabs.com">join-us@farpointlabs.com</a>
      </p>
      <hr />
    </div>
    <div className="fpl__footer-column">
      <h4>Pop in and say hello</h4>
      <hr />
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d620.7384302490892!2d-0.13870627074625852!3d51.514065033326986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d523fb5c39%3A0xdc09f8b001e0c19e!2sFarpoint+Labs!5e0!3m2!1sen!2suk!4v1548865042711"
        width="100%"
        height="350px"
        frameBorder="0"
        allowFullScreen
      />
      <h4>Find us on Social Media</h4>
      <hr />
      <div className="fpl__footer-row">
        <a
          aria-label="See our twitter"
          href="https://www.twitter.com/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          aria-label="Our github"
          href="https://www.github.com/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <a
          aria-label="See our LinkedIn"
          href="https://www.linkedin.com/company/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </div>
      <hr />
      <div className="fpl__footer-row">
        <Link to="/cookies">Cookies</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <hr />
      <Link to="/">
        <p>{`© Farpoint Labs ${footerDate} `}</p>
      </Link>
    </div>
  </footer>
);

export default Footer;
