import React from "react";
import PropTypes from "prop-types";

const logo = ({ mode, highlight, ...rest }) => {
  const main = mode === "light" ? "#000000" : "#ffffff";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 3120 400" {...rest}>
      <defs />
      <path
        fill={main}
        d="M793 192v91h-37V58h39c19 0 33 1 42 4 10 3 19 8 26 15 13 13 19 29 19 48 0 20-6 37-20 49a82 82 0 01-56 18h-13zm0-32h10c31 0 47-12 47-36 0-23-16-35-49-35h-8v71zM1505 58v226h-36V58h36zM1629 284V58l164 150V59h34v224l-164-149v150h-34zM1998 90v193h-34V90h-51V58h137v32h-52zM2281 58v193h66v32h-100V58h34zM2756 284V58h35c16 0 28 1 38 4 9 2 17 6 23 12 7 5 12 12 16 21s6 17 6 26c0 16-7 30-20 41a59 59 0 0140 57c1 18-6 33-19 45-7 7-16 12-26 15s-24 5-39 5h-54zm35-129h11c14 0 23-3 29-8 6-6 9-14 9-25s-3-19-9-24c-6-6-15-9-27-9h-13v66zm0 97h22c16 0 28-3 35-9 8-7 12-15 12-25s-4-18-11-25c-8-6-21-10-40-10h-18v69zM3114 95l-26 16c-5-9-10-15-14-17-5-3-11-5-18-5-9 0-17 3-23 8-5 5-8 11-8 19 0 11 7 19 23 26l21 9c18 7 31 15 39 26 8 10 12 22 12 37a68 68 0 01-69 69c-19 1-35-5-47-16a77 77 0 01-22-47l33-8c1 13 4 22 8 27 6 9 16 14 29 14 10 0 18-3 25-10s10-16 10-26c0-5 0-8-2-12l-5-10c-2-3-6-5-10-8-3-3-8-5-14-7l-20-9c-30-13-45-31-45-55 0-17 7-30 19-41s28-17 46-17c25 0 44 12 58 37zM574 188l66 96h-39l-61-93h-6v93h-32V58h38c28 0 48 6 61 17 13 12 20 29 20 49 0 16-4 30-13 42-8 11-20 18-34 22zm-40-26h10c31 0 46-13 46-37 0-23-15-35-45-35h-11v72zM354 232h-96l-25 52h-37L307 58l108 226h-38l-23-52zm-14-30l-33-72-35 72h68zM2608 232h-96l-25 52h-36l111-226 107 226h-37l-24-52zm-13-30l-34-72-34 72h68zM116 90H35v54h74v32H35v108H0V58h116v32z"
      />
      <path
        fill={main}
        fillRule="evenodd"
        d="M1109 255a84 84 0 100-168 84 84 0 000 168zm0 29a113 113 0 100-226 113 113 0 000 226z"
        clipRule="evenodd"
      />
      <path
        fill={highlight}
        fillRule="evenodd"
        d="M1251 171c0-78-64-142-142-142V0c94 0 171 77 171 171h-29zM1109 371c111 0 200-90 200-200h29c0 126-102 229-229 229v-29z"
        clipRule="evenodd"
      />
    </svg>
  );
};

logo.propTypes = {
  mode: PropTypes.oneOf(["light", "dark"]),
  highlight: PropTypes.string,
};

logo.defaultProps = {
  mode: "light",
  highlight: "#3477db",
};

export default logo;
