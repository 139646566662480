import { Link } from "gatsby";
import React from "react";
import Logo from "../logo";
import {
  BLUE,
  // GREEN, VALENCIA, FUCHSIA,
} from "../../utils/constants";
import "./style.css";

// const colors = [BLUE, GREEN, VALENCIA, FUCHSIA];
// const rotateColor = (setState) => {
//   setTimeout(() => {
//     const color = colors.pop();
//     setState(color);
//     document.documentElement.style.setProperty("--current-color", color);
//     colors.unshift(color);
//   }, 60000);
// };

const Header = () => (
  // const [color, setColor] = React.useState();
  // rotateColor(setColor);
  <header className="fpl__header">
    <Link to="/">
      <Logo mode="dark" highlight={BLUE} />
    </Link>
    <div className="fpl__header--links">
      <div>
        <Link to="/contact">Contact</Link>
      </div>
      {/* <Link to="/case-studies">Case Studies</Link> */}
      {/* <Link to="/investors">Investors</Link> */}
      <div>
        <Link to="/join-us">Join us</Link>
      </div>
    </div>
  </header>
);
export default Header;
