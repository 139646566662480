import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import "./style.css";

const ParallaxImage = ({ url, children, height }) => (
  <section
    style={{ backgroundImage: `url(${url})` }}
    className={ClassNames(
      "fpl__parallax",
      height && `fpl__parallax--${height}`,
    )}
  >
    {children}
  </section>
);

ParallaxImage.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOf(["fullScreen", "halfScreen", "bar"]),
  url: PropTypes.string.isRequired,
};

ParallaxImage.defaultProps = {
  children: "",
  height: null,
};

export default ParallaxImage;
