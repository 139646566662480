import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import "./style.css";

const Button = ({
  children,
  href,
  onColor,
  variation,
  ...rest
}) => {
  const className = classnames("fpl__button", `fpl__button--${variation}`, {
    "fpl__button--on-color": onColor,
  }, "block-reveal");

  return href ? (
    <a {...rest} className={className} href={href}>
      {children}
    </a>
  ) : (
    <button type="submit" {...rest} className={className}>
      {children}
    </button>
  );
};

Button.propTypes = {
  /** Sets the content of the button */
  children: PropTypes.node.isRequired,
  /** Disables the button */
  disabled: PropTypes.bool,
  /** If provided, instead of rendering a button element, the component renders an anchor tag */
  href: PropTypes.string,
  /** Toggles the on-color variation */
  onColor: PropTypes.bool,
  /** Determines the type of button */
  variation: PropTypes.oneOf(["primary", "secondary"]),
};

Button.defaultProps = {
  disabled: undefined,
  href: undefined,
  onColor: false,
  variation: "primary",
};

export default Button;
